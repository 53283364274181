@import "../../../css/colors";

#jegeravgiftskortBody {
  position: relative;
}
.warning {
  background-color: $yellow-05;
  margin-bottom: 15px;
  padding: 15px;
}
