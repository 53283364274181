@import 'src/css/colors';
@import "src/css/shadows";


input.login-input {
    display: block;
    border: 1px solid #cdcdcd;
    border-radius: 4px;
    padding: .75rem;
    outline: none;
    background: transparent;
    margin-bottom: .5rem;
    font-size: 16px;
    width: 350%;
    max-width: 50%;
    line-height: 1;

    &:hover {
        color: $main-blue-80;
        background-color: $secondary-blue-10;
        border: 4px;
    }

    &:active {
        color: $main-blue-80;
        background-color: $secondary-blue-10;
        transition-duration: 0.1s;
        border: 3px solid;
        box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.35);
    }

    &:focus {
        color: $main-blue-80;
        background-color: $yellow-20;
        border: 3px solid;
        box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.35);
    }
}
.myDiv {
    background-color: $grey-10;
    font-weight: 400;
    min-width: 24px;
    min-height: 24px;
    padding: 3px 9px 0 9px;
    margin-right: 6px;
    color: $black;
    text-align: center;
    vertical-align: middle;
    border-radius: 24px;
}

.login-error {
    border: 0;
    //border-radius: 4px;
    background: $red-50;
    border-radius: 10px;

    padding: 0.75rem;
    //tab-size: 1;
    color: $red-90;
    width: 350%;
    max-width: 50%;
    font-family: Open Sans,arial,sans-serif !important;

    &:hover {
        color: $red-60;
        background-color: $secondary-blue-10;
        border: 4px;
    }
}
label {
    font-weight: 600;
    max-width: 100%;

    /*display: block;*/
    margin: 1rem 0 .5rem;
}
