@import "../../../../css/colors";
@import "../../../../css/shadows";


// JEGERAVGIFTSKORT-TABLE
.jegeravgiftskort-table {
    width: 100%;
}

.jegeravgiftskort-table td, {
    padding: 8px !important;
    text-align: left;
}

.betaling-wrapper {
    overflow: visible;
    margin-top: 15px;
    margin-bottom: 50px;
}

.betalt-wrapper {
    border: 5px solid $green-70;
}

.betalt-wrapper h3 {
    font-weight: bold;
}

.kort-content {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 15px;
}

.kort-content > div {
    margin-left: auto;
    margin-right: auto;
}

.ikke-betalt-wrapper {
    border: 5px solid $red-70;
    padding: 15px;
}

.betalt-wrapper,
.ikke-betalt-wrapper {
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    margin-bottom: 5px;
}

.betalt-tekst {
    color: $green-70;
}

/*QR-code css*/

.qr-field {
    text-align: center;
}

.qrcode {
    margin: auto;
    width: 200px;
}

.betalt-wrapper h2 {
    color: $white;
    font-size: 24px;
    margin: 0 !important;
}

.betalt-titlebar {
    background-color: $green-70;
    margin: -1px;
    padding: 10px;
}

.jr-logo {
    display: block;
    height: auto;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    max-width: 220px;
}
.jegeravgiftskort-vaapen-table-wrapper {
    margin-top: 15px;
}

.state-logo {
    font-size: 1.8em;
    line-height: 0.7em;
    margin-right: 0.4em;
}

.jegerbilde {
    display: block;
    max-width:230px;
    width: 100%;
    margin-left: 2em;
}

