@import "../../../../css/colors";
@import "../../../../css/shadows";

// Vaapen input

input.vaapen-input {
  background: transparent;
  border: 1px solid $grey-20;
  border-radius: 4px;
  display: block;
  font-size: 16px;
  line-height: 1;
  margin-bottom: .5rem;
  max-width: 100%;
  outline: none;
  padding: .75rem;
  width: 100%;

  &:hover {
    background-color: $secondary-blue-10;
    border: 4px;
    color: $main-blue-80;
  }

  &:active {
    background-color: $secondary-blue-10;
    border: 3px solid;
    box-shadow: 2px 4px 8px $grey-80;
    color: $main-blue-80;
    transition-duration: 0.1s;
  }
  &:focus {;
    background-color: $yellow-20;
    border: 3px solid;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
    color: #133349;
    padding: 8px 10px 7px;
    webkit-box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
  }
}

// Varsel ved feil respons

.nettverksfeil {
  color: $red-70;
  font-size: 16px;
}

// Errors vaapen-område

.error-filling {
  background: $yellow-05;
  border: 0;
  border-radius: 4px;
  color: $red-70;
  font-family: "Open Sans", sans-serif;
  padding-left: 1rem;
  padding-right: 1rem;
  tab-size: 1;

  &:hover {
    background-color: $secondary-blue-10;
    border: 4px;
    color: $red-70;
  }
}

  label {
    font-weight: 600;
    max-width: 100%;

    /*display: block;*/
    margin: 1rem 0 .5rem;
  }

