@import "../../../../css/colors";
@import "../../../../css/shadows";

.btn {
  align-items: flex-start;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: Open Sans,arial,sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.5em;
  min-width: 64px;
  padding: 10px 16px 10px 16px;
  position: relative;
  text-align: center;
  text-indent: 0;
  text-rendering: auto;
  text-shadow: none;
  width: auto;
  word-spacing: normal;

  &:not(:disabled) {
    &:focus, &:active {
    }
  }

  &.btn-main {
    background-color: $main-blue-70;
    border: 3px solid transparent;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
    color: $white;
    font-weight: 600;
    padding: 8px 10px 7px;
    webkit-box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);

    &:disabled {
      background-color: $grey-05;

    }

    &:hover {
      background-color: $secondary-blue-80;
      border: 3px solid transparent;
      border-bottom: 3px solid #133349;
      color: $white;
    }

    &:focus {;
      background-color: $yellow-20;
      border: 3px solid;
      box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
      color: #133349;
      padding: 8px 10px 7px;
      webkit-box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
    }

    &:active {
      background-color: $white;
      border: 3px solid;
      box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.35);
      color: $secondary-blue-80;
      transition-duration: 0.1s;
    }
  }

  &.btn-simple {
    background-color: transparent;
    border: 3px solid transparent;
    box-shadow: none;
    color: $main-blue-80;
    font-size: 14px;
    padding: 8px 10px 7px;
    text-decoration: none;

    &:disabled {
      background-color: $grey-05;
    }

    &:not(:disabled) {
      &.download {
        text-decoration: none;

        &:hover, &:focus, &:active {
          text-decoration: underline;
        }
      }

      &:hover {
        background-color: $secondary-blue-10;
        border: 4px;
        color: $main-blue-80;
      }

      &:focus {
        background-color: $yellow-20;
        border: 3px solid;
        box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
        color: #133349;
        padding: 8px 10px 7px;
        webkit-box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
      }

      &:active {
        background-color: $secondary-blue-10;
        border: 3px solid;
        box-shadow: 2px 4px 8px $grey-95;
        color: $main-blue-80;
        transition-duration: 0.1s;
      }
    }
  }

  &.btn-trash {
    background-color: transparent;
    border: 3px solid transparent;
    box-shadow: none;
    color: $main-blue-80;
    font-size: 14px;
    padding: 8px 10px 7px;
    text-decoration: none;


        &:hover, &:focus, &:active {
          text-decoration: underline;
        }
      }

      &:hover {
        background-color: $secondary-blue-10;
        border: 4px;
        color: $red-60;
      }

      &:focus {
        background-color: $yellow-20;
        border: 3px solid;
        box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
        color: #133349;
        padding: 8px 10px 7px;
        webkit-box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
      }

      &:active {
        background-color: $secondary-blue-10;
        border: 3px solid;
        box-shadow: 2px 4px 8px $grey-95;
        color: $main-blue-80;
        transition-duration: 0.1s;
      }
  }
  svg {
    margin: 0 0.50rem 0 0;
  }

