@import "src/css/colors.scss";
@import "src/css/shadows.scss";


html, body {
    font-size: 16px !important;
}


h1, h2, h3, h4, h5, h6 {
    font-family: Open Sans,arial,sans-serif;
    color: $grey-90;
}

@media (max-width: 420px) {
    h1, h2 {
        font-size:1.8rem;
    }
}

h1, h2 {
    margin-top: 1em;
}

h3, h4, h5, h6 {
    font-weight: 600;
}

h2 {
    font-size: 1.75rem;
}

h3 {
    margin-top: 0.5rem;
    font-size: 17px;
    color: $main-blue-90;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 17px;
}

strong {
    font-weight: 600;
}

section#links {
    padding-left: 15px;
    padding-right: 15px;
}

a:hover {
    font-weight: normal;
}

p {
    font-size: 16px;
    line-height: 1.5em;
    color: $grey-90;
}
.intro {
    font-size: 22px;
    color: $black;
    line-height: 30px;
    margin-top: 1rem;
}

.fade:not(.show) {
    opacity: 1;
}

.form-item {
    border: 1px solid $grey-05;
}

input.form-item::placeholder,
.dropdown div.default.text {
    color: $grey-10 !important;
}


.d-contents {
    display: contents;
}

li.punkt {
    font-size: 17px;
    margin: 0;
    padding: 0;
}

