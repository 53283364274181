@import "../../../../css/colors";
@import "../../../../css/shadows";

$font-family: Open Sans,arial,sans-serif !important;;

@mixin break {
  thead {
    display: none;
  }

  tr {
    display: block;
    margin-bottom: 5px;
  }

  td {
    border-bottom: 0;
    display: block;
    padding-left: 130px;
    position: relative;
    text-align: left;
    max-width: 75ch;


    &:last-child {
      border-bottom: 1px solid $grey-10;
    }

    &::before {
      align-items: center;
      color: $white;
      content: attr(data-heading);
      background-color: $green-70;
      display: flex;
      font-size: 17px;
      height: 100%;
      justify-content: center;
      left: 0;
      padding: 0 5px;
      position: absolute;
      top: 0;
      width: 120px;;
    }
  }
}
*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: $font-family;
}

.header {
  background-color: $grey-05;
  margin-bottom: 40px;
  padding: 50px 15px;
  text-align: center;
}

.table-container {
  max-width: 1200px;
  width: 100%;

  &__title {
    background-color: $main-blue-80;
    color: $grey-05;
    padding: 3px;
    text-align: center;

    h2 {
      font-size: 24px;
      font-weight: 300;
    }
  }

  &__table {
    border-collapse: collapse;
    width: 100%;
    thead {
      &:hover {
        background-color: $grey-05;
        border: 4px;
        color: $main-blue-80;
      }
      tr {
        background-color: transparent;
      }
    }

    td, th {
      border: 1px solid $grey-20;
      padding: 10px;
      text-align: center;

      &:hover {
        background-color: $secondary-blue-10;
        border: 4px;
        color: $main-blue-80;
      }
      &:active {
        background-color: $secondary-blue-10;
        border: 3px solid;
        box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.35);
        color: $main-blue-80;
        transition-duration: 0.1s;
      }
    }

    tr {
      &:nth-child(even) {
        background-color: $grey-05;
      }
    }

    &--break-lg {

      @media (max-width: 991px) {
        @include break;
      }
    }

    &--break-md {

      @media (max-width: 767px) {
        @include break;
      }
    }

    &--break-sm {

      @media (max-width: 575px) {
        @include break;
      }
    }
  }
}

.positive {
  color: $green-70;
}
.negative {
  color: $red-70;
}
