@import 'src/css/colors';

#betalingButton {
    margin-top: 20px;
}
.button-right {
    float: right;
}
.line {
    >hr {
        border: 0;
        border-top: 1px solid $main-blue-80;
        height: 0;
        margin-bottom: 3px;
        margin-top: 3px;
    }
    height: 7px;
}
