//Colors from BRsys : Brukskvalitet
// Farger fra fargepalett 3.0
// Main-blue
$main-blue-05: #e9f1ff;
$main-blue-10: #d1e4ff;
$main-blue-20: #9ecaff;
$main-blue-30: #76adfb;
$main-blue-40: #5391e9;
$main-blue-50: #2b77cc;
$main-blue-60: #2b77cc;
$main-blue-70: #004785;
$main-blue-80: #00315e;
$main-blue-90: #001c3a;
$main-blue-95: #001127;
// Secondary-blue
$secondary-blue-05: #e5f3f9;
$secondary-blue-10: #c9e7f4;
$secondary-blue-30: #70b3d7;
$secondary-blue-40: #4e99bd;
$secondary-blue-50: #3b7d9b;
$secondary-blue-60: #2c647e;
$secondary-blue-70: #1f4b63;
$secondary-blue-80: #133349;
$secondary-blue-90: #071d2c;
$secondary-blue-95: #03121d;
// Greys
$white: #ffffff;
$grey-05: #ebf2f3;
$grey-10: #d7e5e7;
$grey-20: #b9cacb;
$grey-30: #9faeaf;
$grey-40: #879394;
$grey-50: #6d7879;
$grey-60: #576061;
$grey-70: #414849;
$grey-80: #2c3132;
$grey-90: #191c1c;
$grey-95: #0f1112;
$black: #000000;
// Green (Success, Positive)
$green-05: #ccfdd0;
$green-10: #87fb92;
$green-20: #47e25c;
$green-30: #35c449;
$green-40: #2ba63c;
$green-50: #218730;
$green-60: #196d25;
$green-70: #0e5218;
$green-80: #05390d;
$green-90: #012104;
$green-95: #001502;
// Yellow (Warning, Focus)
$yellow-05: #fff0c7;
$yellow-10: #ffe176;
$yellow-20: #ecc238;
$yellow-30: #cda728;
$yellow-40: #ae8d19;
$yellow-50: #8e730a;
$yellow-60: #735c00;
$yellow-70: #574500;
$yellow-80: #3c2f00;
$yellow-90: #231b00;
$yellow-95: #171000;
// Orange (Info, Status, Neutral)
$orange-05: #ffeee2;
$orange-10: #ffdcc2;
$orange-20: #ffb781;
$orange-30: #f49435;
$orange-40: #d77900;
$orange-50: #b36000;
$orange-60: #944b00;
$orange-70: #733600;
$orange-80: #522300;
$orange-90: #321200;
$orange-95: #220a00;
// Red (Error, Fault, Negative)
$red-05: #ffeced;
$red-10: #fedada;
$red-20: #fab5b5;
$red-30: #f58e8e;
$red-40: #ef6363;
$red-50: #d9393a;
$red-60: #b32728;
$red-70: #8c1718;
$red-80: #65090a;
$red-90: #400202;
$red-95: #2d0000;
// Purple (Meta, Bonus, Secondary status)
$purple-05: #f4eeff;
$purple-10: #eaddff;
$purple-20: #d7baff;
$purple-30: #c497fb;
$purple-40: #b272f4;
$purple-50: #a243f0;
$purple-60: #8926d0;
$purple-70: #6819a1;
$purple-80: #4a0d74;
$purple-90: #2d0549;
$purple-95: #1e0233;

