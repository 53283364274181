@import "css/bootstrap4-header-override";
@import "css/colors";

.state {
    font-size: 17px;
    font-weight: 400;
    padding-right: 50px;
    padding-top: 20px;
}

.linkButton {
    background: $white;
    border: none;
    color: $secondary-blue-70;
    cursor: pointer;
    font: inherit;
    margin-left: 0;
    margin-right: 5px;
    padding-left: 0;
    text-decoration: underline;
}

.icon-info {
    color: $orange-50;
    font-size: 1.8em;
    line-height: 0.7em;
    margin-right: 0.4em;
}
a.link {
    color: $secondary-blue-80;
    line-height: inherit;
    text-decoration: underline;

    &:hover {
        background-color: $secondary-blue-10;
        border: 4px;
        color: $main-blue-80;
        font-weight: bold;
    }

    &:active {
        background-color: $secondary-blue-10;
        border: 3px solid;
        color: $secondary-blue-80;
        transition-duration: 0.1s;
    }
}

 .ie-warning {
     color: red;
     margin: 30px;
 }
