@import "src/css/colors";
@import "src/css/shadows";

:root {
  --color: $main-blue-80;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

// Radio betaling-radiovalg
.radio-bet {
  color: var(--color);
  display: grid;
  font-size: 1rem;
  grid-gap: 0.5em;
  grid-template-columns: min-content auto;


  &:focus-within {
    .radio__label {
      transform: scale(1);
      background-color: $yellow-20;
      box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
      opacity: 1;
      outline: 3px solid transparent;
      padding: 8px 10px 7px;
      webkit-box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
    }
  }
  &:hover {
    .radio__input + .radio__label{
      background-color: $secondary-blue-10;
      border: 3px solid;
      box-shadow: 2px 4px 8px $shadow-dp2;
      color: $main-blue-80;
    }
  }
}

// Radio samtykke
.radio {
  color: var(--color);
  display: grid;
  font-size: 1.5rem;
  grid-gap: 0.5em;
  grid-template-columns: min-content auto;

  &:focus-within {
    .radio__label {
      transform: scale(1);
      background-color: $yellow-20;
      box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
      opacity: 1;
      outline: 3px solid transparent;
      padding: 8px 10px 7px;
      webkit-box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
    }
  }
  &:hover {
    .radio__input + .radio__label{
      background-color: $secondary-blue-10;
      border: 3px solid;
      box-shadow: 2px 4px 8px $shadow-dp2;
      color: $main-blue-80;
    }
  }
}

.radio__label {
  line-height: 1;
  opacity: 1;
  transition: all .2s cubic-bezier(.46, -0.265, .48, 1.38);
}

.radio__input {
  display: flex;

  input {
    height: 0;
    opacity: 0;
    width: 0;

    &:focus + .radio__control {
      box-shadow: 0 0 0 0.05em $yellow-20, 0 0 0.15em 0.1em currentColor;
    }
  }
}

.radio-gradient input:checked + .radio__control {
  background: radial-gradient(currentcolor 50%, rgba(255, 0, 0, 0) 51%);
}

.radio-before {
  .radio__control {
    display: grid;
    place-items: center;
  }

  input + .radio__control::before {
    border-radius: 50%;
    box-shadow: inset 0.5em 0.5em currentColor;
    content: "";
    height: 0.30em;
    transform: scale(0);
    transition: 180ms transform ease-in-out;
    width: 0.30em;
  }

  input:checked + .radio__control::before {
    transform: scale(1);
  }
}

.radio__control {
  border: 0.1em solid currentColor;
  border-radius: 50%;
  display: block;
  height: 1em;
  transform: translateY(-0.05em);
  width: 1em;
}
