@import 'src/css/colors';

.section-header {
    background-color: $white;
    border: 1px solid $grey-20;
    padding: 10px 15px;

    h2 {
        margin-top: 10px;
    }
}
.section-body {
    background-color: $main-blue-05;
    border: 1px solid $grey-20;
    clear: both;
    overflow: hidden;
    padding: 10px 15px 10px;
}
.price {
    text-align: right;
}
