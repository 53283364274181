@import "src/css/colors";

.helptext-icon {
  color: $orange-30;
  font-size: 1.8em;
  margin-right: 0.4em;
  margin-top: 0;
  outline: none;
}
.helptext {
  color: $main-blue-95;
}
