@import "src/css/colors.scss";

.br-service-button {
  background-color: $white;
  background-image: url(//scf.brreg.no/bilder/pil_hoyre_litenknapp.svg);
  background-position: 94% 50%;
  background-repeat: no-repeat;
  background-size: 36px 36px;
  border-bottom: 2px solid $main-blue-80;
  border-left: 1px solid $main-blue-80;
  border-right: 2px solid $main-blue-80;
  border-top: 1px solid $main-blue-80;
  display: inline-block;
  float: none;
  font-size: 18px !important;
  margin-left: 0;
  margin-right: 0;
  padding-left: 30px;
  padding-right: 90px;
  text-align: left;
  text-decoration: $main-blue-80;
  width: 100%;

  &:hover {
    background-color: $secondary-blue-10;
    border: 4px;
    color: $main-blue-80;
  }

  &:focus {
    background-color: $yellow-20;
    border: 3px solid;
    box-shadow: 1px 2px 4px $grey-80;
    color: $main-blue-80;
  }

  &:active {
    background-color: $secondary-blue-10;
    border: 3px solid;
    box-shadow: 2px 4px 8px $grey-80;
    color: $main-blue-80;
    transition-duration: 0.1s;
  }
}

.br-button {
  border-radius: 0;
  font-weight: bold;
  line-height: 1em;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

