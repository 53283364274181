@import "../../../css/colors";
@import '../../../css/responsive';


.user-header {
  background: $grey-90;
  color: $white;
  height: 40px;
  text-align: right;
  width: 100%;

  &__inner {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: auto;
    max-width: 1140px;
    width: 100%
  }

  &__fullname {
    color: $white;
    display: inline-block;
    font-style: italic;
    margin: 0.25em 0.75em;
    padding-top: 1px;
  }

  .logout-btn {
    background: $white;
    border: none;
    border-radius: 4px;
    color: $grey-90;
    font-size: 0.75em;
    line-height: 2;
    margin: 7px;
    padding: 0 1em;
    top: calc(50% - 18px/2);
  }
}
