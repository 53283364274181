@import "../../../css/colors";

$breadcrumb-breakpoint1max: 550px;
$breadcrumb-breakpoint1min: 551px;
$breadcrumb-breakpoint2max: 992px;
$breadcrumb-breakpoint2min: 993px;

@mixin crumb-separator {
  &:not(:first-child):before {
    color: $grey-30;
    content: ' | ';
    margin: auto 0.3rem;
  }
}

.breadcrumb {
  &__list {
    margin: 0;
    padding: 0 1rem;
  }

  &__item {
    display: inline-block;
    margin: 0;
    padding: 0;

    &--active {
      color: $grey-80;
      font-weight: bold;
    }

    @media (max-width: $breadcrumb-breakpoint1max) {
      &:not(.breadcrumb__item--parent) {
        display: none;
      }

      &:before {
        color: $grey-30;
        content: "< ";
        padding: 0 5px;
      }
    }

    @media (min-width: $breadcrumb-breakpoint1min) and (max-width: $breadcrumb-breakpoint2max) {
      @include crumb-separator;

      &:not(.breadcrumb__item--parent):not(.breadcrumb__item--active) {
        display: none;
      }

      &--parent:before {
        display: none;
      }
    }

    @media (min-width: $breadcrumb-breakpoint2min) {
      @include crumb-separator;
    }

    a {
      color: $main-blue-80;
      text-decoration: underline;

      &:hover {
        font-weight: normal;
        text-decoration: none;
      }

      &:visited {
        color: $grey-70;
      }
    }
  }
}
.clearfix {
  clear: both;
}
