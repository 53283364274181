.section-body {
label {
    font-weight: normal;
}
}
.selected-row {
label {
    font-weight: bold;
}
.price {
    font-weight: bold;
}
}
.radio-price {
    margin: 1rem 0 .5rem;
}
