@import "../../../../css/colors";
@import "../../../../css/shadows";

// Medlemskap input

input.medlemsnummer-input {
  display: block;
  border: 1px solid $grey-20;
  border-radius: 4px;
  padding: .75rem;
  outline: none;
  background: transparent;
  margin-bottom: .5rem;
  font-size: 16px;
  width: 100%;
  max-width: 100%;
  line-height: 1;

  &:hover {
    color: $main-blue-80;
    background-color: $secondary-blue-10;
    border: 4px;
  }

  &:active {
    color: $main-blue-80;
    background-color: $secondary-blue-10;
    transition-duration: 0.1s;
    border: 3px solid;
    box-shadow: 2px 4px 8px $grey-80;
  }

  &:focus {
    color: $main-blue-80;
    background-color: $yellow-20;
    border: 3px solid;
    box-shadow: 1px 2px 4px $grey-80;
  }
}

// Varsel ved feil respons

.nettverksfeil {
  color: $red-70;
  font-size: 16px;
}

// Errors Medlemskap-område

.error-filling {
  border: 0;
  border-radius: 4px;
  background: $yellow-05;
  padding-left: 1rem;
  padding-right: 1rem;
  tab-size: 1;
  color: $red-70;
  font-family: "Open Sans", sans-serif;

  &:hover {
    color: $red-70;
    background-color: $secondary-blue-10;
    border: 4px;
  }
}

label {
  font-weight: 600;
  max-width: 100%;

  /*display: block;*/
  margin: 1rem 0 .5rem;
}

.positive {
  color: $green-70;
}
.negative {
  color: $red-70;
}
